import PropTypes from "prop-types"
import React from "react"
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import logo from '../images/logo.png';
import { FormattedMessage } from "gatsby-plugin-intl";
import {Link} from "gatsby";

const Header = ({ siteTitle, hideSignUp }) => (
  <Navbar collapseOnSelect expand="md" bg="light">
    <Container>
      <Navbar.Brand href="/">
        <img
          src={logo}
          height="25"
          className="d-inline-block align-top"
          alt="Baushe"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="#how-it-works"><FormattedMessage id="header.links.how-it-works" defaultMessage="How it Works" /></Nav.Link>
          <Nav.Link href="/about-us"><FormattedMessage id="header.links.about-us" defaultMessage="About us" /></Nav.Link>
          <Nav.Link href="/circles"><FormattedMessage id="header.links.circles" defaultMessage="Circles" /></Nav.Link>
          <Nav.Link className={hideSignUp ? '' : 'mr-3'} href="/blog"><FormattedMessage id="header.links.blog" defaultMessage="Blog" /></Nav.Link>
        </Nav>
        {!hideSignUp && <Button as={Link} to="/sign-up" variant="outline-primary"><FormattedMessage id="header.links.sign-up" defaultMessage="Sign Up" /></Button>}
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  hideSignUp: PropTypes.bool
}

Header.defaultProps = {
  siteTitle: ``,
  hideSignUp: false,
}

export default Header
