import React from 'react'
import {Container} from "react-bootstrap";
import logo from "../images/logo.png";
import {Link} from "gatsby";
import {FormattedMessage} from "gatsby-plugin-intl";

const Footer = () => (
	<footer className="bg-light p-3 p-md-5">
		<Container>
			<div className="d-flex justify-content-between flex-column flex-md-row">
				<div>
					<ul className="list-unstyled list-inline font-weight-bold mb-1">
						<li className="list-inline-item"><Link to="/#how-it-works" className="text-dark"><FormattedMessage id="header.links.how-it-works" defaultMessage="How it Works" /></Link></li>
						<li className="list-inline-item ml-md-3"><Link to="/#features" className="text-dark"><FormattedMessage id="header.links.features" defaultMessage="Features" /></Link></li>
						<li className="list-inline-item ml-md-3"><Link to="/about-us" className="text-dark"><FormattedMessage id="header.links.about-us" defaultMessage="About Us" /></Link></li>
						<li className="list-inline-item ml-md-3"><Link to="/circles" className="text-dark"><FormattedMessage id="header.links.circles" defaultMessage="Circles" /></Link></li>
						<li className="list-inline-item ml-md-3"><Link to="/blog" className="text-dark"><FormattedMessage id="header.links.blog" defaultMessage="Blog" /></Link></li>
						<li className="list-inline-item ml-md-3"><a className="text-dark" href="mailto:info@baubo.care"><FormattedMessage id="header.links.contact" defaultMessage="Contact" /></a></li>
					</ul>
					<p>
						<small>Baushe is a property of legal entity Baubo B.V. registered in the Netherlands with KVK 81947143 · <Link to="/privacy"><FormattedMessage id="signup-form.privacy" defaultMessage="Privacy policy" /></Link></small>
					</p>
					<small className="text-muted">Designed and built with <span className="text-danger">♥</span> in Amsterdam</small>
				</div>
				<div>
					<img
						src={logo}
						height="25"
						className="d-inline-block align-top mb-2"
						alt="Baushe"
					/>
				</div>
			</div>
		</Container>
	</footer>
);

export default Footer;
